export type IRevisionChangedCallback = () => void;

export enum EditorViewStates {
  ADDED_TO_CART_MODAL = 'addedToCartModal',
  FORM = 'bookingForm',
}

export enum SlotIds {
  ADDED_TO_CART_MODAL = 'cart-modal-slot',
  CALENDAR_AVAILABILITY = 'calendar-availability-slot',
}
