export enum ComponentDataType {
  MAIN_SECTION = 'TPA',
  MULTI_SECTION = 'TPAMultiSection',
  WIDGET = 'TPAWidget',
}

export enum ComponentType {
  COMPONENT = 'Component',
  PAGE = 'Page',
  CONTAINER = 'Container',
}

export enum EditorComponentsType {
  TPA_GLUED_WIDGET = 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
  TPA_MULTI_SECTION = 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
  TPA_MODAL = 'wysiwyg.viewer.components.tpapps.TPAModal',
  TPA_SECTION = 'wysiwyg.viewer.components.tpapps.TPASection',
  TPA_WIDGET = 'wysiwyg.viewer.components.tpapps.TPAWidget',
}

export interface ComponentStructure {
  id: string;
  style: any;
  data: {
    appDefinitionId?: string;
    applicationId: string;
    id: string;
    metaData: { isPreset: boolean; schemaVersion: string; isHidden: boolean };
    type: string;
    widgetId: string;
    controllerType?: string;
    name?: string;
    settings?: any;
    tpaPageId?: string;
  };
}

export interface GeneralReference {
  id: string;
  type: string;
}
