import { BOOKINGS_DEF_ID } from '../constants';

export async function handleOpenBookingsAddPanel(editorSDK, appToken) {
  const { check, show } = editorSDK.editor.deeplink;
  const addPanel = {
    type: 'addPanel',
    params: [BOOKINGS_DEF_ID],
  };

  const editorDeepLinkToBookingsAddPanel = await check(appToken, addPanel);

  editorDeepLinkToBookingsAddPanel && (await show(appToken, addPanel));
}
