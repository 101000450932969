import {
  BaseComponentModel,
  BaseEditorComponent,
} from './base-editor-component';

export class BookButtonEditorComponentModel extends BaseEditorComponent {
  constructor(
    editorSdkAdapter,
    editorTranslation,
    manageBookingsAction,
    bookButtonSettings,
  ) {
    const componentModel = new BaseComponentModel({
      nickname: 'bookButton',
      label: editorTranslation.t(
        'bookings.book-button.component.label.BookButton',
      ),
      mainAction1: manageBookingsAction,
      mainAction2: bookButtonSettings,
      doubleClickAction: bookButtonSettings,
      helpId: 'dcd84888-5c8f-4845-a631-d12fb34b8421',
      isButtonWidget: true,
    });
    super(editorSdkAdapter, componentModel);
  }
}
