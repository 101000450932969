import { Translations } from './translations';

export const manageBookingsEvent = 'manageBookings';
export const openBookButtonSettingsEvent = 'openBookButtonSettings';
export const openDailyTimeTableSettingsEvent = 'openDailyTimeTableSettings';
export const openDailyTimeTableSettingsTextTabEvent =
  'openDailyTimeTableSettingsTextTab';
export const manageStateEvent = 'manageState';
export const openStaffListSettingsEvent = 'openStaffListSettings';
export const openStaffListTextSettingsEvent = 'openStaffListTextSettings';
export const manageStaffEvent = 'manageStaff';
export const openBookingsAddPanel = 'openBookingsAddPanel';
export const openBookingsPagesPanel = 'openBookingsPagesPanel';
export const createAServiceEvent = 'createAService';

export function generateActions(translation: Translations) {
  return {
    openAddPanel: {
      label: translation.t('bookings-descriptor.actions.open-add-panel'),
      actionId: openBookingsAddPanel,
    },
    createAService: {
      label: translation.t('bookings-descriptor.actions.create-a-service'),
      actionId: createAServiceEvent,
    },
    openPagesPanel: {
      label: translation.t('bookings-descriptor.actions.open-pages-panel'),
      actionId: openBookingsPagesPanel,
    },
    manageBookingsAction: {
      label: translation.t('bookings-pages.actions.manage'),
      actionId: manageBookingsEvent,
    },
    manageStateAction: {
      label: translation.t(
        'bookings.daily-timetable.component.label.SwitchState',
      ),
      actionId: manageStateEvent,
    },
    bookButtonSettings: {
      label: translation.t('bookings.book-button.component.gfpp.Settings'),
      actionId: openBookButtonSettingsEvent,
    },
    openDailyTimeTableSettings: {
      label: translation.t('bookings.daily-timetable.component.gfpp.Settings'),
      actionId: openDailyTimeTableSettingsEvent,
    },
    openDailyTimeTableSettingsTextTab: {
      label: translation.t(
        'bookings.daily-timetable.component.gfpp.change-text',
      ),
      actionId: openDailyTimeTableSettingsTextTabEvent,
    },
    manageStaffAction: {
      label: translation.t('bookings.staff-list.component.gfpp.manage-staff'),
      actionId: manageStaffEvent,
    },
    openStaffListSettings: {
      label: translation.t('bookings.staff-list.component.gfpp.Settings'),
      actionId: openStaffListSettingsEvent,
    },
    openStaffListTextSettings: {
      label: translation.t(
        'bookings.daily-timetable.component.gfpp.change-text',
      ),
      actionId: openStaffListTextSettingsEvent,
    },
  };
}
export type EditorActions = ReturnType<typeof generateActions>;
