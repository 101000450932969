import { SchedulerStyles } from './scheduler-styles';
import { getValueOf, mapColor, mapFont } from '../style-migration-utils';
import {
  getBookingsData,
  getBookingsDefId,
  getComponentData,
  updateComponentData,
  updateComponentStyle,
} from '../editor-sdk-actions';
import { settingsClient, Scope } from '@wix/app-settings-client';

export async function getSchedulerStyles(
  schedulerStyle,
  colorsMap,
): Promise<SchedulerStyles> {
  if (!schedulerStyle.style) {
    return {} as SchedulerStyles;
  }
  const properties = schedulerStyle.style.properties;

  const getLayoutName = (schedulerLayoutname) => {
    switch (schedulerLayoutname) {
      case 'angelina':
        return 'STRIP';
      case 'bar':
        return 'GRID';
      case 'heidi':
      default:
        return 'CLASSIC';
    }
  };
  const getImageType = (schedulerImageType, schedulerLayout) => {
    if (
      schedulerImageType === 'none' ||
      (schedulerLayout === 'bar' && schedulerImageType !== 'round')
    ) {
      return 'rectangle';
    }
    return schedulerImageType;
  };

  console.log('Old Properties:');
  console.table(properties);
  return {
    OFFERINGS_LIST_LAYOUT: getLayoutName(
      properties.param_font_servicesLayoutPreset
        ? getValueOf(properties.param_font_servicesLayoutPreset)
        : 'bar',
    ),
    IMAGE_SHAPE_OPTION: getImageType(
      properties.param_font_servicesLayoutImageType
        ? getValueOf(properties.param_font_servicesLayoutImageType)
        : 'rectangle',
      getValueOf(properties.param_font_servicesLayoutPreset),
    ),
    CATEGORY_NAME_COLOR: mapColor(
      properties.param_color_colorCategoryText,
      colorsMap,
    ),
    CATEGORY_NAME_FONT: mapFont(properties.param_font_fontCategoryText),
    CATEGORY_HOVER_TEXT_COLOR: mapColor(
      properties.param_color_colorCategoryText,
      colorsMap,
    ),
    CATEGORY_SELECTED_TEXT_COLOR: mapColor(
      properties.param_color_colorCategoryTextSelected,
      colorsMap,
    ),
    CATEGORY_SELECTED_UNDERLINE_COLOR: mapColor(
      properties.param_color_colorCategoryTextSelected,
      colorsMap,
      0,
    ),
    CATEGORY_DIVIDER_COLOR: mapColor(
      properties.param_color_colorCategoryText,
      colorsMap,
      0.2,
    ),
    CATEGORY_HOVER_BORDER_COLOR: mapColor(
      properties.param_color_colorCategoryText,
      colorsMap,
      0.6,
    ),
    CATEGORY_SELECTED_BORDER_COLOR: mapColor(
      properties.param_color_colorCategoryTextSelected,
      colorsMap,
    ),
    CATEGORY_BACKGROUND_COLOR: mapColor('color_11', colorsMap, 0),
    CATEGORY_HOVER_BACKGROUND_COLOR: mapColor('color_11', colorsMap, 0),
    OFFERING_NAME_COLOR: mapColor(properties.param_color_colorTitle, colorsMap),
    OFFERING_NAME_FONT: mapFont(properties.param_font_fontTitle, 22),
    OFFERING_DETAILS_COLOR: mapColor(
      properties.param_color_colorIndividualText,
      colorsMap,
    ),
    OFFERING_DETAILS_FONT: mapFont(
      properties.param_font_fontIndividualText,
      15,
    ),
    OFFERING_TAGLINE_COLOR: mapColor(
      properties.param_color_colorIndividualText,
      colorsMap,
    ),
    OFFERING_TAGLINE_FONT: mapFont(
      properties.param_font_fontIndividualText,
      15,
    ),
    OFFERING_MORE_INFO_LABEL_COLOR: mapColor(
      properties.param_color_colorIndividualText,
      colorsMap,
    ),
    OFFERING_MORE_INFO_LABEL_FONT: mapFont(
      properties.param_font_fontIndividualText,
      15,
    ),
    BORDER_COLOR:
      mapColor(
        properties.param_color_colorIndividualCardBorder,
        colorsMap,
        properties['alpha-param_color_colorIndividualCardBorder'],
      ) || mapColor('color_12', colorsMap),
    BACKGROUND_COLOR:
      getValueOf(properties.param_font_servicesLayoutPreset) !== 'angelina'
        ? mapColor(
            properties.param_color_colorCardBackground,
            colorsMap,
            properties['alpha-param_color_colorCardBackground'],
          )
        : mapColor('#ffffff', colorsMap, 0),
    MULTI_OFFERINGS_TITLE_COLOR: mapColor(
      properties.param_color_colorPageTitle,
      colorsMap,
    ),
    MULTI_OFFERINGS_TITLE_FONT: mapFont(
      properties.param_font_fontPageTitle,
      26,
    ),
    MULTI_OFFERINGS_BACKGROUND_COLOR:
      mapColor(
        properties.param_color_colorPageBackground,
        colorsMap,
        properties['alpha-param_color_colorPageBackground'],
      ) || mapColor('#ffffff', colorsMap, 0),
    BUTTON_TEXT_COLOR_FILL: mapColor(
      properties.param_color_colorPrimaryButtonText,
      colorsMap,
    ),
    BUTTON_TEXT_FONT: mapFont(properties.param_font_fontButtonText),
    BUTTON_BACKGROUND_COLOR: mapColor(
      properties.param_color_colorButton,
      colorsMap,
    ),
    DISPLAY_CATEGORIES:
      getValueOf(properties.param_font_servicesLayoutCategoryPosition) !==
      'none',
    DISPLAY_MULTI_OFFERINGS_TITLE:
      getValueOf(properties.param_font_textPageTitle_Checkbox) !== 'false',
    DISPLAY_IMAGE:
      getValueOf(properties.param_font_servicesLayoutImageType) !== 'none',
    MULTI_OFFERINGS_TITLE_TEXT: getValueOf(properties.param_font_textPageTitle),
    BOOK_FLOW_ACTION_TEXT: getValueOf(properties.param_font_textBookButton),
    NO_BOOK_FLOW_ACTION_TEXT: getValueOf(
      properties.param_font_textMoreInfoButton,
    ),
    SERVICE_DIVIDER_COLOR: mapColor(
      properties.param_color_colorIndividualListBorder,
      colorsMap,
      properties['alpha-param_color_colorIndividualListBorder'],
    ),
    TEXT_ALIGNMENT: 'center',
    IMAGE_RESIZE_OPTION: 'crop',
    CATEGORY_LAYOUT_OPTION: 'tags',
    DISPLAY_DIVIDER: false,
    DISPLAY_TAG_LINE: true,
    DISPLAY_DAYS_OFFERED: false,
    DISPLAY_MORE_INFO_LABEL: true,
    PRESERVE_OLD_STYLES: true,
  };
}

export async function setBookingsListStyles(sdk, appToken, newStyles, compId) {
  const { instanceId, instance } = await getBookingsData(sdk, appToken);
  const resetStyles = () => updateComponentStyle(sdk, appToken, compId, {});
  const setExternalId = async function (compPointer, referenceId) {
    await updateComponentData(sdk, appToken, compPointer, { referenceId });
  };
  const getExternalId = async function (compPointer) {
    const { referenceId } = await getComponentData(sdk, appToken, compPointer);
    return referenceId;
  };
  const bookingsDefId = await getBookingsDefId(sdk);
  await resetStyles();
  const appSettings = settingsClient({
    scope: Scope.COMPONENT,
    apiUrl: '//editor.wix.com/_api/app-settings-service',
    adapter: {
      getAppDefId: () => bookingsDefId,
      getInstanceId: () => instanceId,
      getExternalId: () => getExternalId({ type: 'DESKTOP', id: compId }),
      setExternalId: (id) => {
        console.log(
          `Write settings for instanceId: ${instanceId} externalId: ${id}`,
        );
        return setExternalId({ type: 'DESKTOP', id: compId }, id);
      },
      triggerSettingsUpdated: (data, scope) => {},
      onSettingsUpdated: (scope, cb) => {},
      signedInstance: instance,
    },
  });
  const relevantKeys = Object.keys(newStyles).filter(
    (key) => typeof newStyles[key] !== 'undefined',
  );
  return Promise.all(
    relevantKeys.map((relevantKey) =>
      appSettings.set(relevantKey, newStyles[relevantKey]),
    ),
  );
}
