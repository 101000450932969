import { ComponentStructure, GeneralReference } from './domain';
import {
  getComponentRefById,
  getPageRefByComponentRef,
  updateComponentData,
  updatePageData,
} from '../utils/editor-sdk-actions';

export class MigrationHandler {
  constructor(private editorSDK, private appToken, private tpaId) {}

  public async migrateSection(tpaSectionStructure, newWidgetId, newPageId) {
    const tpaMainSectionRef = await this.getSectionRef(tpaSectionStructure);
    await this.replaceMainSection(tpaMainSectionRef, newWidgetId);
    const tpaMainSectionPageRef: GeneralReference = await this.getPageRef(
      tpaMainSectionRef,
    );
    await this.switchPageId(tpaMainSectionPageRef, newPageId);
  }

  private async replaceMainSection(tpaMainSectionRef, newWidgetId) {
    await updateComponentData(
      this.editorSDK,
      this.appToken,
      tpaMainSectionRef,
      { widgetId: newWidgetId },
    );
  }

  private async switchPageId(pageRef: GeneralReference, newPageId) {
    await updatePageData(this.editorSDK, this.appToken, pageRef, {
      tpaPageId: newPageId,
    });
  }

  private getSectionRef(
    sectionStructure: ComponentStructure,
  ): Promise<GeneralReference> {
    return getComponentRefById(
      this.editorSDK,
      this.appToken,
      sectionStructure.id,
    );
  }

  private getPageRef(sectionRef: GeneralReference): Promise<GeneralReference> {
    return getPageRefByComponentRef(this.editorSDK, this.appToken, sectionRef);
  }
}
