import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import { getAsset } from './editor/utils/get-asset';

const i18nextCore = (locale, currentVersion, cb) => {
  return i18next.use(i18nextXHRBackend).init(
    {
      lng: locale,
      fallbackLng: 'en',
      keySeparator: '$',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: getAsset(currentVersion, 'locale/messages_{{lng}}.json'),
        crossDomain: true,
      },
    },
    cb,
  );
};

export const i18n = ({
  locale,
  currentVersion = '1.165',
}): Promise<{ t: any; i18nInstance: any }> => {
  return new Promise((resolve, reject) => {
    const i18nInstance = i18nextCore(locale, currentVersion, (err, t) =>
      err ? reject(`i18n Booking Util Failed with Error: ${err}`) : resolve({ t, i18nInstance }),
    );
  });
};
