import { BookingsBOLink } from '@wix/bookings-catalog-calendar-viewer-utils';

const bookingsAddNewServicePanel = `bookings/${BookingsBOLink.ADD_NEW_SERVICE}`;
const bookingsServicesPanel = `bookings/${BookingsBOLink.SERVICES}`;

export async function openBookingsDashboardAddNewService(
  editorSdkAdapter,
  editorSDK,
  appToken,
) {
  await editorSDK.editor.openDashboardPanel(appToken, {
    url: bookingsAddNewServicePanel,
    closeOtherPanels: false,
  });
  editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE', true);
}
export async function openManageBookings(
  editorSdkAdapter,
  editorSDK,
  appToken,
) {
  await editorSDK.editor.openDashboardPanel(appToken, {
    url: bookingsServicesPanel,
    closeOtherPanels: false,
  });
  editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE', true);
}
