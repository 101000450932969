export async function openBar(
  sdk,
  appToken,
  translation,
  titleTranslationKey,
  numberOfSteps,
) {
  try {
    await sdk.editor.openProgressBar(appToken, {
      title: translation.t(titleTranslationKey),
      totalSteps: numberOfSteps,
    });
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: PROGRESS_BAR_OPEN`;
    console.error(errorMessage);
  }
}

export async function setBarStep(
  sdk,
  appToken,
  translation,
  stepTranslation,
  stepNumber,
) {
  try {
    await sdk.editor.updateProgressBar(appToken, {
      stepTitle: translation.t(stepTranslation),
      currentStep: stepNumber,
    });
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: PROGRESS_BAR_STEP`;
    console.error(errorMessage);
  }
}

export async function closeBar(sdk, appToken, isError = false) {
  try {
    await sdk.editor.closeProgressBar(appToken, {
      isError,
    });
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: PROGRESS_BAR_CLOSE`;
    console.error(errorMessage);
  }
}
