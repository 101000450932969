import {
  ActionDefinition,
  ConnectionBuilder,
} from '../manifest/manifest-definitions';
import { EditorSdkAdapter } from '@wix/bookings-adapter-editor-sdk';

export class ConnectedComponentModel {
  readonly connection: any;
  readonly role: string;
  readonly labelKey: string;

  constructor(role, connection, labelKey?: string) {
    this.connection = connection;
    this.role = role;
    this.labelKey = labelKey;
  }

  get shouldTranslateLabel() {
    return !!this.labelKey;
  }
}

interface BaseComponentAdditionalConfiguration {
  shouldHideMainAction2OnMobile?: boolean;
}

export interface BaseComponentModelProps {
  nickname: string;
  label: string;
  mainAction1: ActionDefinition;
  mainAction2: ActionDefinition;
  doubleClickAction?: ActionDefinition;
  helpId: string;
  connectedComponents?: ConnectedComponentModel[];
  isButtonWidget?: boolean;
  linkedStyles?: string[][];
  additionalConfiguration?: BaseComponentAdditionalConfiguration;
}

export class BaseComponentModel {
  readonly nickname: string;
  readonly label: string;
  readonly mainAction1: ActionDefinition;
  readonly mainAction2: ActionDefinition;
  readonly doubleClickAction: ActionDefinition;
  readonly helpId: string;
  readonly isButtonWidget: boolean;
  readonly linkedStyles: string[][];
  readonly additionalConfiguration: BaseComponentAdditionalConfiguration;
  connectedComponents?: ConnectedComponentModel[];

  constructor({
    nickname,
    label,
    mainAction1,
    mainAction2,
    helpId,
    connectedComponents,
    isButtonWidget,
    linkedStyles,
    additionalConfiguration,
    doubleClickAction,
  }: BaseComponentModelProps) {
    this.nickname = nickname;
    this.label = label;
    this.mainAction1 = mainAction1;
    this.mainAction2 = mainAction2;
    this.helpId = helpId;
    this.isButtonWidget = isButtonWidget;
    this.connectedComponents = connectedComponents;
    this.linkedStyles = linkedStyles || [];
    this.additionalConfiguration = additionalConfiguration;
    this.doubleClickAction = doubleClickAction;
  }
}

export class BaseEditorComponent {
  readonly baseComponentModel: any;
  readonly editorSdkAdapter: EditorSdkAdapter;

  constructor(editorSdkAdapter, componentModel: BaseComponentModel) {
    this.editorSdkAdapter = editorSdkAdapter;
    this.baseComponentModel = componentModel;
  }

  getLinkedStyles(styleRole: string) {
    return this.baseComponentModel.linkedStyles
      .find((linkedStylesGroup) =>
        linkedStylesGroup.find(
          (linkedStyleRole) => linkedStyleRole === styleRole,
        ),
      )
      .filter((linkedStyleRole) => linkedStyleRole !== styleRole);
  }

  async handleStylesChange(controllerRef, styledComponentRef) {
    const styledComponentRole = await this.editorSdkAdapter.getComponentRole(
      styledComponentRef,
    );
    const sourceStyle = await this.editorSdkAdapter.getComponentStyle(
      styledComponentRef,
    );
    const linkedStyles = this.getLinkedStyles(styledComponentRole);
    const updateStylesPromises = [];
    linkedStyles.forEach((linkedStyleRole) => {
      updateStylesPromises.push(
        (async () => {
          const targetComponentRef =
            await this.editorSdkAdapter.getConnectedComponentRefByRole(
              controllerRef,
              linkedStyleRole,
            );
          await this.editorSdkAdapter.setComponentStyle(
            targetComponentRef,
            sourceStyle,
          );
        })(),
      );
    });
    return Promise.all(updateStylesPromises);
  }

  shouldDisplayMainAction2OnMobile() {
    return !(
      this.baseComponentModel.additionalConfiguration &&
      this.baseComponentModel.additionalConfiguration
        .shouldHideMainAction2OnMobile
    );
  }

  get connection() {
    const baseConnectionBuilder = new ConnectionBuilder()
      .withNickname(this.baseComponentModel.nickname)
      .withLabel(this.baseComponentModel.label)
      .withMainAction1(this.baseComponentModel.mainAction1)
      .withMainAction2(
        this.baseComponentModel.mainAction2,
        this.shouldDisplayMainAction2OnMobile(),
      )
      .withHelpId(this.baseComponentModel.helpId)
      .withoutAddElement();

    const connections = {};
    if (this.baseComponentModel.connectedComponents?.length > 0) {
      this.baseComponentModel.connectedComponents.forEach((component) => {
        connections[component.role] = component.connection;
      });
      baseConnectionBuilder.withConnections(connections);
    }
    if (this.baseComponentModel.isButtonWidget) {
      baseConnectionBuilder
        .withoutFirstTimeModal()
        .withDisabledLinkAbility()
        .withDisabledRotatability();
    }
    return baseConnectionBuilder.build();
  }
}
