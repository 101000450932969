import { getValueOf } from '../style-migration-utils';

export function getNewCalendarPageData(oldCalendarPageProperties) {
  const nextButton =
    getValueOf(oldCalendarPageProperties.param_font_textNextStepLabel) ||
    getValueOf(oldCalendarPageProperties.param_font_textNextStepLabelGroups);
  const newCalendarPagedData: any = {
    nextButton,
  };

  Object.keys(newCalendarPagedData).forEach(
    (key) =>
      newCalendarPagedData[key] === undefined &&
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newCalendarPagedData[key],
  );
  return newCalendarPagedData;
}
