import Experiments from '@wix/wix-experiments';
import { PETRI_SCOPE } from '../constants';

let experiments: Experiments;

export const isExperimentEnabled = async (experiment: string) => {
  if (!experiments) {
    await conduct();
  }
  return experiments.get(experiment) === 'true';
};

const conduct = () => {
  experiments = new Experiments();
  return experiments.load(PETRI_SCOPE);
};
