import { getValueOf } from '../style-migration-utils';

export function getNewFormPageData(oldFormPageProperties) {
  const pricingPlanText =
    getValueOf(
      oldFormPageProperties.param_font_textViewPricingLabelIndividual,
    ) ||
    getValueOf(oldFormPageProperties.param_font_textViewPricingLabelGroups);
  const newFormPagedData: any = {
    pricingPlanText,
  };

  Object.keys(newFormPagedData).forEach(
    (key) =>
      newFormPagedData[key] === undefined &&
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newFormPagedData[key],
  );
  return newFormPagedData;
}
