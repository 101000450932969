import { EcomStyleParams } from '@wix/ecom-platform-sdk/dist/es/src/types';

export function mapThankYouPageParams(
  oldTYPProperties,
): Promise<EcomStyleParams> {
  const { colors, fonts } = oldTYPProperties;

  return Promise.resolve({
    thankYouPage: {
      colorParams: {
        WIDGET_BACKGROUND: colors.colorPageBackground,
        BACKGROUND: colors.colorPageBackground,
        BORDER_COLOR: colors.colorCongratsBoxBorder,
        DIVIDERS_COLOR: colors.colorCongratsBoxSeparator,
        TITLES_COLOR: colors.colorPageTitle,
        PAGE_TEXT_COLOR: colors.colorBodyText,
        INFO_TEXT_COLOR: colors.colorBodyText,
        LINKS_TEXT_COLOR: colors.colorLink,
      },
      fontParams: {
        TITLES_FONT: fonts.fontPageTitle,
        PAGE_TEXT_FONT: fonts.fontBodyText,
        INFO_TEXT_FONT: fonts.fontBodyText,
      },
      booleanParams: {
        CONTINUE_BROWSING_LINK_SELECTION: mapBoolean(
          fonts.textCongradsBookAnother_Checkbox,
        ),
      },
      textsParams: {
        TITLE: mapText(fonts.textCongrads),
        SUBTITLE: mapText(fonts.textCongradsNotes),
        CONTINUE_BROWSING_CAPTION: mapText(fonts.textCongradsBookAnother),
      },
    },
  });
}

const mapBoolean = (oldValue) => {
  const value = oldValue?.value;
  if (value) {
    switch (value) {
      case 'false':
        return false;
      case 'true':
        return true;
      default:
        return;
    }
  }
};

const mapText = (oldValue) => {
  return oldValue?.value || oldValue?.cssFontFamily;
};
